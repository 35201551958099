import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const VARIANTS = gql`
  query Variants {
    variants @client {
      selectedProductVariants
      currentProductVariant
      totalPrice
    }
  }
`;

export const UPDATE_VARIANTS = gql`
  mutation UpdateVariants(
    $selectedProductVariants: [ProductVariantType]
    $currentProductVariant: ProductVariantType
    $totalPrice: Int
  ) {
    updateVariants(
      selectedProductVariants: $selectedProductVariants
      currentProductVariant: $currentProductVariant
      totalPrice: $totalPrice
    ) @client
  }
`;

export const ALL_BANNER = gql`
  query AllBanner {
    allBanner {
      id
      bannerUrl
      bannerAlt
      bannerTitle
      image
      imageMobile
    }
  }
`;

export const UPDATE_HIT = gql`
  mutation UpdateHit($bannerId: ID) {
    updateHit(bannerId: $bannerId) {
      bannerHit
    }
  }
`;

export const PRODUCT = gql`
  query Product($code: String!) {
    product(code: $code) {
      id
      name
      code
      discountRate
      unit
      volume
      deliveryType
      origin
      packageType
      expiryDate
      description
      shortDescription
      content
      images: productimageSet {
        id
        image
      }
      options {
        id
        name
        onSale
        discountedPrice
        shortName
        longName
        price
      }
    }
  }
`;

export const ALL_PRODUCT = gql`
  query AllProduct($ordering: String, $limit: Int, $offset: Int) {
    allProduct(ordering: $ordering, limit: $limit, offset: $offset) {
      data {
        id
        created
        highlightText
        productOrder
        name
        code
        description
        shortDescription
        productCategory {
          id
          name
        }
        discountRate
        unit
        volume
        deliveryType
        origin
        packageType
        expiryDate
        images {
          id
          product {
            id
          }
          image
        }
        options {
          id
          price
          discountedPrice
          onSale
          name
          shortName
          longName
        }
      }
      totalCount
    }
  }
`;

export const PRODUCT_CATEGORY = gql`
  query ProductCategory {
    productCategory {
      id
      name
      productSet {
        id
      }
    }
  }
`;
